<template>
  <div>
    <header id="header" class="fixed-top border-bottom bg-main shadow-sm">
      <div class="container-fluid shadow-sm">
        <nav class="navbar navbar-expand-md navbar-dark px-0 pr-sm-3">
          <a @click="back" class="btn p-0 mr-md-4 text-light h5 mb-0">
            <i class="fas fa-arrow-left"></i>
          </a>
          <span
            class="
              navbar-brand
              bg-transparent
              rounded
              p-0
              text-center
              mr-0 mr-md-5
            "
          >
            <span class="h6 font-weight-bold text-truncate">Pesanan</span>
          </span>
          <div class="text-nowrap order-md-last">
            <a class="btn px-0 d-inline-block" @click="shareData">
              <i class="fa fa-share-alt text-light"></i>
            </a>
          </div>
        </nav>
      </div>
      <!-- .container -->
    </header>

    <div class="container-fluid mt-header">
      <div class="row" id="body-sidemenu">
        <!-- MAIN -->
        <div id="main-content" class="col pb-5 with-fixed-sidebar">
          <div
            class="
              jumbotron jumbotron-fluid
              mb-0
              shadow-sm
              bg-secondary
              no-side-margin
            "
          >
            <div class="container text-center">
              <p class="h5 my-2 text-white">Ready Stock (Non-PO)</p>
            </div>
          </div>
          <div class="row horizontal-menu-100">
            <div class="col-12 px-0">
              <div class="list-group list-group-horizontal text-center">
                <router-link
                  to="/jual/pesanan-group"
                  class="list-group-item flex-fill"
                  >PRE ORDER</router-link
                >
                <a
                  href="javascript:void(0);"
                  class="list-group-item flex-fill on"
                  >READY STOCK</a
                >
              </div>
            </div>
            <!-- .col -->
          </div>
          <!-- .row -->

          <div class="row mb-3">
            <div class="col-12 px-0">
              <ul class="list-group rounded-0 mt-3">
                <li
                  class="
                    list-group-item
                    d-flex
                    justify-content-between
                    align-items-center
                    border-left-0 border-right-0
                    p-0
                  "
                >
                  <router-link
                    :to="'/jual/pesanan/produk/' + state.data.id"
                    class="py-2 pl-3 text-uppercase stretched-link"
                    >Daftar Produk yang Dipesan</router-link
                  >
                  <i class="fa fa-caret-right mr-3"></i>
                </li>
                <li
                  class="
                    list-group-item
                    d-flex
                    justify-content-between
                    align-items-center
                    border-left-0 border-right-0
                    p-0
                  "
                >
                  <router-link
                    :to="'/jual/pesanan-nonPo/rekap/' + state.id"
                    class="py-2 pl-3 text-uppercase stretched-link"
                    >Rekap Ready Stock
                  </router-link>
                  <i class="fa fa-caret-right mr-3"></i>
                </li>
              </ul>
            </div>
            <!-- .col -->
          </div>
          <!-- .row -->

          <form @submit.prevent="searchData">
            <div class="input-group input-group-sm my-3">
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Cari..."
                  v-model="state.search"
                />
                <span class="input-group-append">
                  <button type="submit" class="btn btn-outline-main">
                    <i class="fa fa-search"></i>
                  </button>
                </span>
              </div>
            </div>
          </form>

          <div class="small text-truncate">Daftar Pesanan Non-PO:</div>

          <div id="resultList" class="row mt-3" v-if="state.exist">
            <div
              class="col-12 col-md-6 col-lg-4 col-xl-3"
              v-for="pesan in state.pesanan"
              :key="pesan.id"
              v-show="pesan.status"
            >
              <div class="card mb-2">
                <div class="card-body p-0">
                  <div class="row no-gutters">
                    <div class="col-9 border-right p-2 pl-3">
                      <router-link
                        :to="'/jual/pesanan/customer/' + pesan.id"
                        class="
                          card-title
                          text-main
                          font-weight-bold
                          d-block
                          mb-1
                          mt-1
                          card-link
                          stretched-link
                          text-truncate
                        "
                        >{{ pesan.pelanggan.prefix }}
                        {{ pesan.pelanggan.name }}</router-link
                      >
                      <p class="card-text mb-0 text-truncate">
                        {{ pesan.po.name }}, {{ pesan.pelanggan.alamat }}
                        {{ pesan.pelanggan.blok_no }}
                      </p>
                      <div v-html="sumData(pesan)"></div>
                    </div>
                    <div class="col-3 p-2 pl-2">
                      <div class="ml-1">
                        <form>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              :checked="pesan.siap"
                            />
                            <label
                              class="custom-control-label pt-1"
                              for="siap"
                              @click="
                                changeStatus('siap', pesan.siap, pesan.id)
                              "
                              >Siap?</label
                            >
                          </div>
                        </form>
                        <form>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              :checked="pesan.terkirim"
                            />
                            <label
                              class="custom-control-label pt-1"
                              for="kirim"
                              @click="
                                changeStatus(
                                  'terkirim',
                                  pesan.terkirim,
                                  pesan.id
                                )
                              "
                              >Kirim?</label
                            >
                          </div>
                        </form>
                        <form>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              :checked="pesan.selesai"
                            />
                            <label
                              class="custom-control-label pt-1"
                              for="selesai"
                              @click="
                                changeStatus('selesai', pesan.selesai, pesan.id)
                              "
                              >Selesai?</label
                            >
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- .col -->
          </div>
          <!-- .row -->

          <router-link
            to="/jual/pesanan-nonPo/tambah"
            id="btn-float"
            class="
              bg-warning
              position-fixed
              rounded-circle
              shadow
              text-dark text-center
            "
          >
            <div class="d-flex justify-content-center mt-3">
              <i class="fas fa-cart-plus" style="font-size: 25px"></i>
            </div>
          </router-link>
        </div>
        <!-- main-content -->
      </div>
      <!-- .row -->
    </div>
    <!-- .container -->
  </div>
</template>
<script>
import { reactive, onMounted } from "vue";
import axios from "axios";
import { useRoute } from "vue-router";
import moment from "moment";
import { useVueSweetAlert2 } from "../../../useVueSweetAlert2.js";
import Share from "../../../components/social_media/Share";

export default {
  setup() {
    const route = useRoute();
    const $swal = useVueSweetAlert2();
    const state = reactive({
      data: {},
      finish: false,
      id: "0",
      url: process.env.VUE_APP_API_URL,
      pesanan: {},
      originalPesanan: {},
      exist: false,
      search: "",
    });
    const loadData = async () => {
      state.finish = false;
      let id = route.params.id;
      await axios.get("/api/seller/getNonPo/" + id).then((res) => {
        if (res.data.success) {
          state.data = res.data.data;
          state.finish = true;
          if (res.data.pesanan.length > 0) {
            state.exist = true;
            state.pesanan = res.data.pesanan;
            state.originalPesanan = res.data.pesanan;
          } else {
            state.exist = false;
            state.pesanan = {};
            state.originalPesanan = {};
          }
        }
      });
    };
    onMounted(() => {
      state.id = route.params.id;
      loadData();
    });
    const formatDate = (dt) => {
      return moment(dt).format("dddd, DD MMMM YYYY");
    };
    const sumData = (dt) => {
      let pesanan = dt.detail.length;
      let jumlah = 0;
      let total = 0;
      dt.detail.map((detail) => {
        jumlah += Number(detail.jumlah);
        total += Number(detail.total);
      });
      let cur = Math.ceil(Number(total));
      let mo = cur.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      let ret = mo.split(".")[0];

      return (
        '<p class="card-text mb-0 text-truncate">' +
        '<small class="text-muted">' +
        pesanan +
        " pesanan, " +
        jumlah +
        " pcs," +
        '<span class="currency"> Rp. ' +
        ret +
        "</span></small></p>"
      );
    };
    const searchData = () => {
      let result = [];
      const data = state.originalPesanan;
      Object.values(data).find((dt, index) => {
        if (
          dt.pelanggan.name.match(state.search) ||
          dt.pelanggan.prefix.match(state.search)
        ) {
          result.push(data[index]);
        }
      });
      state.pesanan = result;
    };
    const setAktif = (dt) => {
      $swal
        .fire({
          text: "Apakah Anda Yakin?",
          showCancelButton: true,
          confirmButtonText: "Ya",
          cancelButtonText: "Tidak",
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios.get("/api/seller/pesanan/setActive/" + dt.id).then((res) => {
              if (res.data.success) {
                loadData();
                $swal.fire({
                  icon: "success",
                  text: "Status Berhasil Dirubah!",
                });
              }
            });
          }
        });
    };
    const changeStatus = (dt, value, id) => {
      $swal
        .fire({
          text: "Apakah Anda Yakin?",
          showCancelButton: true,
          confirmButtonText: "Ya",
          cancelButtonText: "Tidak",
        })
        .then((result) => {
          if (result.isConfirmed) {
            let form = {
              status: dt,
              value: !value,
            };
            axios.post("/api/seller/getNonPo/" + id, form).then((res) => {
              if (res.data.success) {
                loadData();
                $swal.fire({
                  icon: "success",
                  text: "Status Berhasil Dirubah!",
                });
              }
            });
          }
        });
    };
    const shareData = () => {
      let dt = {
        title: state.data.name,
        url: process.env.VUE_APP_HOME_URL + "/home/po/" + state.data.key,
        description: "Po Group",
      };
      Share.run("whatsapp", dt);
    };
    const back = () => {
      window.history.back();
    };
    return {
      state,
      formatDate,
      sumData,
      searchData,
      setAktif,
      changeStatus,
      shareData,
      back,
    };
  },
};
</script>